import { useContext, useEffect, useState } from "react"
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import LoginModal from "../login-modal/LoginModal";
import { Session } from "../utilities/Session";
import UserInfoModel from "../tp-core-types/UserInfoModel";
import GenericPageHeader from "../generic-page-header/GenericPageHeader";
import "./TimesheetDesktop.scss";
import TimesheetPayPeriods from "./TimesheetPayPeriods";
import { GetHcpActiveContractsWithTimesheetsResult, TimesheetPayPeriod } from "../tp-core-types/Timesheet";
import displayAsDate from "../utilities/displayAsDate";


export default function TimesheetDesktop(){
    const [showLoginModalVisibility, setShowLoginModalVisibility] = useState(false);
    const [timesheetData, setTimesheetData] = useState<GetHcpActiveContractsWithTimesheetsResult[]>([]);
    const [payPeriods, setPayPeriods] = useState<Array<TimesheetPayPeriod>| null>(null);
    const userInfoModel = useContext(UserInfoModel)  
    const [selectedContractNum, setSelectContractNum]= useState <number|null> (null);


useEffect(() => {
    userInfoModel.subscribeForLogout(handleLogout)
    if (Session.getHcpLoginToken() !== ""){
      {/*  getSubmittedJobResults(); */}
        setShowLoginModalVisibility(false);
    } else {
        setShowLoginModalVisibility(true);
    }

    getTimesheetData();
    return function cleanup() {
        userInfoModel.unsubscribeForLogout(handleLogout)
    }
}, []);
const handleLogin = async() => {
    setShowLoginModalVisibility(false);
   {/*  getSubmittedJobResults(); */}
}
const handleLogout = () => {
    setShowLoginModalVisibility(true);
}

const getTimesheetData = async() => {
    const timesheetData = await TPCoreAPI.getHcpActiveContractsWithTimesheets();
    setTimesheetData(timesheetData);

    if(timesheetData.length > 0){
        let pp = timesheetData[0].timesheets;

        pp = pp.sort( (a,b)=>{return b.startDate.localeCompare(a.startDate)});
        setPayPeriods(pp);
        setSelectContractNum(timesheetData[0].contractNum);
    }
    return;
}
function setShowContract( contractNum: number ){
    
    if(timesheetData == null)
        return;

    setSelectContractNum(contractNum);

    for(let i=0;i<timesheetData.length;i++){
        const contract = timesheetData[i];

        if(contract.contractNum == contractNum)
        {
            
            let pp = contract.timesheets;

            pp = pp.sort( (a,b)=>{return b.startDate.localeCompare(a.startDate)});
            setPayPeriods(pp);
            return;
        }
    }
} 

return (

    <div id="Timesheet">
        <LoginModal isLoginForm={true} isVisible={showLoginModalVisibility} setVisibility={(value: boolean) => setShowLoginModalVisibility(value)} successfulLogin={handleLogin} headerText="Your session has expired, please log in again." canBeDismissed={false}/>
        <GenericPageHeader title={`Timesheet`}></GenericPageHeader>           
        <div className="container-timesheets" data-testid="container-timesheets" >           
            <div className="container-tab" data-testid="container-tab">
                {timesheetData.map((contract) => {
                return (
                    <div className="container-content-list"
                      key={contract.contractNum}
                      {...contract.contractNum==selectedContractNum && {className:"container-content-list-selected container-content-list"}}


                    onClick={() => {
                        setShowContract(contract.contractNum);  
                        
                    }}> 
                        <div className="container-content-list-facility"  
                            data-testid={contract.contractNum}>
                            {contract.facilityName + "," }  
                        </div> 
                        <div >
                            {contract.city + ", " + contract.state + " | " + displayAsDate(contract.startDate) + " - " + displayAsDate(contract.endDate)} 
                        </div> 
                    </div>
                       
                )})}    
            </div>
            <div className="content-container"> 
                <div className="timesheet-container">    
                    
                    <div className="timesheet-container-left">
                        <div className="timesheet-container-header">Pay Periods</div>
                        <TimesheetPayPeriods payPeriods={payPeriods}/>  
                    </div>     
                    <div className="timesheet-container-right"></div>
                </div> 
            </div>            
            
        </div> 
                    
    </div>
)

}
