import { useContext, useEffect, useState } from "react"
import TimesheetMobile from "./TimesheetMobile";
import TimesheetDesktop from "./TimesheetDesktop";

export default function Timesheet(){
    const getIsMobile = (): boolean => {
        return window.matchMedia("(max-width: 1250px)").matches;
    }
    const [isMobile, setIsMobile] = useState(getIsMobile());

  
    useEffect(()=>{
        const evtListener = ()=>{
            const mobile = getIsMobile();
            setIsMobile(mobile);
        }
        window.addEventListener('resize', evtListener);
        //clean up after the component umounts.
        return () => {
            window.removeEventListener('resize',evtListener);
        }
    },[])
     
    return (
            <>{isMobile && 
                <TimesheetMobile></TimesheetMobile>
            }
            {!isMobile && 
                <TimesheetDesktop></TimesheetDesktop>
             }
             </>
    )
 
}