import { useContext, useEffect, useState } from "react"
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import LoginModal from "../login-modal/LoginModal";
import { Session } from "../utilities/Session";
import UserInfoModel from "../tp-core-types/UserInfoModel";
import GenericPageHeader from "../generic-page-header/GenericPageHeader";
import "./TimesheetMobile.scss";
import TimesheetPayPeriods from "./TimesheetPayPeriods";
import { GetHcpActiveContractsWithTimesheetsResult, TimesheetPayPeriod } from "../tp-core-types/Timesheet";
import displayAsDate from "../utilities/displayAsDate";

export default function TimesheetMobile(){
    const [showLoginModalVisibility, setShowLoginModalVisibility] = useState(false);
    const [timesheetData, setTimesheetData] = useState<GetHcpActiveContractsWithTimesheetsResult[]>([]);
    const [payPeriods, setPayPeriods] = useState<Array<TimesheetPayPeriod>| null>(null);
    const userInfoModel = useContext(UserInfoModel)

    useEffect(() => {
        userInfoModel.subscribeForLogout(handleLogout)
        if (Session.getHcpLoginToken() !== ""){
          {/*  getSubmittedJobResults(); */}
            setShowLoginModalVisibility(false);
        } else {
            setShowLoginModalVisibility(true);
        }

        getTimesheetData();
        return function cleanup() {
            userInfoModel.unsubscribeForLogout(handleLogout)
        }
    }, []);
    
    
    const handleLogin = async() => {
        setShowLoginModalVisibility(false);
       {/*  getSubmittedJobResults(); */}
    }
    const handleLogout = () => {
        setShowLoginModalVisibility(true);
    }

    const getTimesheetData = async() => {
        const timesheetData = await TPCoreAPI.getHcpActiveContractsWithTimesheets();
        setTimesheetData(timesheetData);

        if(timesheetData.length > 0){
            let pp = timesheetData[0].timesheets;

            pp = pp.sort( (a,b)=>{return b.startDate.localeCompare(a.startDate)});
            setPayPeriods(pp);
        }
        return;
    }

    function contractsDropdown_OnChange(e: any){
        const selectedContractNum = e.target.value;
        
        if(timesheetData == null)
            return;

        for(let i=0;i<timesheetData.length;i++){
            const contract = timesheetData[i];

            if(contract.contractNum == selectedContractNum)
            {
                let pp = contract.timesheets;

                pp = pp.sort( (a,b)=>{return b.startDate.localeCompare(a.startDate)});
                setPayPeriods(pp);
                return;
            }
        }
    }
 
    return (
        <div id="Timesheet">
            <LoginModal isLoginForm={true} isVisible={showLoginModalVisibility} setVisibility={(value: boolean) => setShowLoginModalVisibility(value)} successfulLogin={handleLogin} headerText="Your session has expired, please log in again." canBeDismissed={false}/>
            <GenericPageHeader title={`Timesheet`}></GenericPageHeader>           
            <div className="container-style" data-testid="container-style">
                <div className="left-pane-container">
                    <div className="timesheets-contract-container">

                        <select data-testid="contracts-ts-display" className="contracts-dropdown" onChange={contractsDropdown_OnChange} >
                            {timesheetData.map((contract) => {
                                return (<option key={contract.contractNum} value={contract.contractNum}>{displayAsDate(contract.startDate) + " - " + displayAsDate(contract.endDate) + " | " + contract.facilityName}</option>)
                            })}
                        </select>

                        <TimesheetPayPeriods payPeriods={payPeriods}/>

                    </div>
                </div>
            </div>
        </div>
    )
 
}