import "./LoadingOverlay.css";
import loading_spinner from "../assets/loading_spinner.gif";

export default function LoadingOverlay() {
    return(
    <div className="loadingOverlayContainer" data-testid="loadingOverlayContainer">
        <div className="loadingOverlaySubContainer">
            <img src={loading_spinner} alt="loading"/>
        </div>
    </div>)
}